import Vue from "vue";
import Router from "vue-router";

import store from '@/store'


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // checkLineUser: checkLineUser,
  // This is for the scroll top when click on any router link
  routes: [

    {
      path: "",
      redirect: "/login",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      meta: { roleUser: ["Admin"] },
      children: [
        {
          name: 'login',
          path: "login",
          component: () => import("@/views/Login"),
          meta: { roleUser: [""] },
          beforeEnter: (to, from, next) => {
            let roleUser = store.state.defaultSystem.user ? store.state.defaultSystem.user.role : ''
            if (roleUser == 'Super Admin') {
              next({ path: "/site" })
            } else if (roleUser == 'Root') {
              next({ path: "/users" })
            }  else if (roleUser == 'Partner') {
              next({ path: "/partnerbrands" })
            } else {
              next()
            }
          },
        },
      ],
    },


    {
      path: "",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          path: "users",
          component: () => import("@/views/SuperAdminManager/User"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          path: "site",
          component: () => import("@/views/SuperAdminManager/Site"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          name: "banner-dashboard",
          path: "banner-dashboard/:id",
          component: () => import("@/views/SuperAdminManager/BannerDashboard.vue"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "ads",
          component: () => import("@/views/SuperAdminManager/Ads"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "banner-slide",
          component: () => import("@/views/SuperAdminManager/BannerSlide"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "banner-ads",
          component: () => import("@/views/SuperAdminManager/BannerAds"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "brands",
          component: () => import("@/views/SuperAdminManager/Brands"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "reward",
          component: () => import("@/views/SuperAdminManager/Reward"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          name: "rewardreport",
          path: "reward/report/:name/:id",
          component: () => import("@/views/SuperAdminManager/RewardReport"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "deals",
          component: () => import("@/views/SuperAdminManager/Deal"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          name: "dealsreport",
          path: "deals/report/:name/:id",
          component: () => import("@/views/SuperAdminManager/DealReport"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          name: "deals-dashboard",
          path: "deals/dashboard/:name/:id",
          component: () => import("@/views/SuperAdminManager/DealDashboard"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "transports",
          component: () => import("@/views/SuperAdminManager/Transport"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "festival",
          component: () => import("@/views/SuperAdminManager/Festival"),
          meta: { roleUser: ["Super Admin"] },
        },
        {
          path: "sms",
          component: () => import("@/views/SuperAdminManager/SMS"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          path: "otp",
          component: () => import("@/views/SuperAdminManager/OTP"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          name: "reportotp",
          path: "reportotp/:name/:id",
          component: () => import("@/views/SuperAdminManager/ReportLog/ReportOtp"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          name: "reportsms",
          path: "reportsms/:name/:id",
          component: () => import("@/views/SuperAdminManager/ReportLog/ReportSms"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          name: "reportcoin",
          path: "reportcoin/:name/:id",
          component: () => import("@/views/SuperAdminManager/ReportLog/ReportCoin"),
          meta: { roleUser: ["Root", "Super Admin"] },
        },
        {
          path: "partnerbrands",
          component: () => import("@/views/Partner/Brands"),
          meta: { roleUser: ["Partner"] },
        },
        {
          path: "report",
          component: () => import("@/views/Partner/Report"),
          meta: { roleUser: ["Partner"] },
        },
        {
          name: "reportdetail",
          path: "report/:id",
          component: () => import("@/views/Partner/ReportDetail"),
          meta: { roleUser: ["Partner"] },
        },
      ],
    },
    {
      path: "*",
      name: 'error',
      component: () => import("@/views/Error"),
      meta: { roleUser: ["Root", "Super Admin", "Admin", "Customer", "unknown"] },

    },
  ],
});

router.beforeEach((to, from, next) => {
  let role = store.state.defaultSystem.user;
  let roleName = "";
  if (role == null) {
    roleName = "unknown";
  } else {
    roleName = role.role;
  }
  
  if (!to.meta.roleUser[0]) {
    return next();
  }

  let checkRoleRoutes = to.meta.roleUser
    ? to.meta.roleUser.includes(roleName)
    : next({ name: "error" });

  if (checkRoleRoutes) {
    return next();
  }else{
    return next({ name: "error" });
  }
})


export default router;
