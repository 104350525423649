import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import defaultSystem from './modules/default'
import partner from './modules/partner'

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})


export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    // strict: true,
    modules: {
        defaultSystem,
        partner
    }
});